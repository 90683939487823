<template>
  <div
    class="bg-slate-800/50 flex items-center justify-between px-4 py-1 text-xs"
  >
    <div class="flex items-center gap-7">
      <InertiaLink
        v-for="item in navItems"
        :href="item.url"
        :class="$page.url == item.url ? 'text-white' : 'text-slate-400'"
        >{{ item.name }}</InertiaLink
      >
    </div>
    <div class="flex items-center gap-5">
      <BaseMenu :items="items" position="right">Settings</BaseMenu>
      <a href="/users/sign_out" class="text-xs">Logout</a>
      <UserProfile :user="user" />
    </div>
  </div>
</template>

<script setup>
import UserProfile from "@/components/UserProfile.vue";
import { Link as InertiaLink } from "@inertiajs/vue3";
const props = defineProps({
  user: {},
});
const navItems = [
  { name: "Data Assets", url: "/" },
  { name: "Uptime Monitors", url: "/uptime_monitors" },
];

const items = [
  { label: "Tags", href: "/tags" },
  { label: "ReadMe", href: "/readme" },
];
</script>
