import "./application.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";

import AppLayout from "@/components/AppLayout.vue";
import axios from "axios";
import TheTIEDesignSystem from "@the-tie/the_tie_design_system";
import "@the-tie/the_tie_design_system/dist/style.css";

import "highlight.js/styles/github-dark.css";
import hljs from "highlight.js/lib/core";
import sql from "highlight.js/lib/languages/sql";
import hljsVuePlugin from "@highlightjs/vue-plugin";

hljs.registerLanguage("sql", sql);

document.addEventListener("DOMContentLoaded", () => {
  axios.defaults.headers.common["X-CSRF-Token"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
  document.getElementById("app").click();
  createInertiaApp({
    resolve: (name) => {
      const pages = import.meta.glob("../pages/**/*.vue");
      const page = pages[`../pages/${name}.vue`]();
      page.then((module) => {
        module.default.layout = module.default.layout || AppLayout;
      });
      return page;
    },
    setup({ el, App, props, plugin }) {
      const app = createApp(App, props);
      app.use(plugin);
      app.use(TheTIEDesignSystem);
      app.use(hljsVuePlugin);
      app.mount(el);
    },
  });
});
